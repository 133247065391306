<template>
  <div
    v-if="!color"
    :class="getMetroClass"
  />
  <div
    v-else
    class="a-metro a-metro--color"
    :style="{ background: color }"
  >
    <AImage
      src="/assets/images/metro.svg"
      width="16"
      height="16"
      provider="public"
    />
  </div>
</template>

<script lang="ts" setup>
import { defineComponent, computed } from 'vue'
import type { PropType } from 'vue'
import AImage from '@/components/atoms/Image/AImage.vue'

export type MetroCity = 'msc' | 'spb' | 'ekb' | 'nn' | 'volgograd' | 'samara'
defineComponent({ name: 'AMetro' })

const props = defineProps({
  city: {
    type: String as PropType<MetroCity>,
    default: 'msc'
  },
  line: {
    type: String,
    default: '1'
  },
  logo: {
    type: Boolean,
    default: false
  },
  color: {
    type: String,
    default: ''
  }
})
const maxLineValue = {
  msc: 15,
  spb: 5
}

const getMetroClass = computed(() => {
  const line = +props.line
  const city = props.city
  const logo = props.logo ? 'i-metro-' : 'a-metro-'

  switch (city) {
    case 'msc':
    case 'spb':
      if (line < 1 || line > maxLineValue[city] || line === 13) {
        return `a-metro ${logo}${city}-1`
      }
      return `a-metro ${logo}${city}-${line}`
    default:
      return `a-metro ${logo}${city}`
  }
})
</script>

<style lang="postcss">
.a-metro {
  display: inline-flex;

  &:not([class*="i-"]) {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: var(--border-radius-full);
    background: var(--metro-circle-color, var(--primary));
  }

  &.a-metro--color {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }
}

.a-metro-msc-1 {
  --metro-circle-color: #da2128;
}

.a-metro-msc-2 {
  --metro-circle-color: #48b85e;
}

.a-metro-msc-3 {
  --metro-circle-color: #0078bf;
}

.a-metro-msc-4 {
  --metro-circle-color: #00c1f3;
}

.a-metro-msc-5 {
  --metro-circle-color: #894e35;
}

.a-metro-msc-6 {
  --metro-circle-color: #f58220;
}

.a-metro-msc-7 {
  --metro-circle-color: #8e479b;
}

.a-metro-msc-8 {
  --metro-circle-color: #ffc61a;
}

.a-metro-msc-9 {
  --metro-circle-color: #a1a2a3;
}

.a-metro-msc-10 {
  --metro-circle-color: #b4d445;
}

.a-metro-msc-11 {
  --metro-circle-color: #6ac9c8;
}

.a-metro-msc-12 {
  --metro-circle-color: #acbfe1;
}

.a-metro-msc-14 {
  --metro-circle-color: #00b6be;
}

.a-metro-msc-15 {
  --metro-circle-color: #e66ac0;
}

.a-metro-spb-1 {
  --metro-circle-color: #da2128;
}

.a-metro-spb-2 {
  --metro-circle-color: #0078bf;
}

.a-metro-spb-3 {
  --metro-circle-color: #48b85e;
}

.a-metro-spb-4 {
  --metro-circle-color: #f58220;
}

.a-metro-spb-5 {
  --metro-circle-color: #8e479b;
}

.a-metro-ekb {
  --metro-circle-color:
    linear-gradient(0deg, #0e7f4c 0%, #64c299 100%),
    var(--color-white);
}

.a-metro-nn {
  --metro-circle-color: #16598d;
}

.a-metro-volgograd {
  --metro-circle-color: #d40000;
}

.a-metro-samara {
  --metro-circle-color: #0b4372;
}
</style>
